import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "./github-markdown.css"
import App from "./App"
import { AuthProvider } from "./context/AuthProvider"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ToastsProvider } from "./context/ToastsProvider"
import ToastContainer from "./components/ToastContainer"
import { ThemeProvider } from "./context/ThemeProvider"
import { BannersProvider } from "./context/BannersProvider"
import BannerContainer from "./components/BannerContainer"
import CookieConsent from "react-cookie-consent"
import { Link } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <div className="bg-neutral-100 dark:bg-neutral-800 font-sans">
    <React.StrictMode>
      <ThemeProvider>
        <BrowserRouter>
          <BannersProvider>
            <BannerContainer />
            <ToastsProvider>
              <ToastContainer />
              <AuthProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </AuthProvider>
            </ToastsProvider>
          </BannersProvider>
          <CookieConsent
            disableStyles={true}
            location="bottom"
            buttonClasses="group relative flex items-stretch justify-center p-0.5 text-center font-medium transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none border border-transparent bg-green-700 text-white focus:ring-4 focus:ring-green-300 enabled:hover:bg-green-800 dark:bg-green-600 dark:focus:ring-green-800 dark:enabled:hover:bg-green-700 rounded-lg"
            buttonText={
              <span className="flex items-stretch transition-all duration-200 rounded-md px-4 py-2 text-sm">
                I understand
              </span>
            }
            containerClasses="fixed left-1/2 -translate-x-1/2 bottom-0 flex w-full md:w-[calc(100%-2rem)] flex-col justify-between rounded-lg border border-neutral-100 bg-white p-4 shadow-sm dark:border-neutral-600 dark:bg-neutral-700 md:flex-row lg:max-w-7xl"
            contentClasses=" items-center flex">
            <p className="text-neutral-700 dark:text-neutral-200 pb-2 md:pb-0 items-center flex">
              We use cookies to enhance the user experience. By using our
              website you agree to our use of cookies.{" "}
              <Link
                to="/privacy"
                className="pl-2 text-red-logo hover:underline hover:text-red-950 cursor-pointer">
                Learn more
              </Link>
            </p>
          </CookieConsent>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  </div>
)
