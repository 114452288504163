import { Tabs } from "flowbite-react"
import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import NavbarPublic from "../../components/navbar/NavbarPublic"
import useAuth from "../../hooks/useAuth"

const Privacy = () => {
  const { auth } = useAuth()
  return (
    <section className="min-h-screen flex flex-col justify-between">
      {auth ? <NavbarPrivate /> : <NavbarPublic />}
      <div className="mb-auto">
        <div className="container mx-auto px-5 md:px-16">
          <section id="terms_conditions">
            <Tabs
              aria-label="Terms and Conditions"
              className=" mt-10"
              theme={{
                tablist: {
                  styles: {
                    pills:
                      "flex-wrap font-medium text-sm text-neutral-500 dark:text-neutral-400 space-x-2",
                  },
                  tabitem: {
                    base: "flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-neutral-400 disabled:dark:text-neutral-500 focus:ring-4 focus:ring-red-900 focus:outline-none",
                    styles: {
                      pills: {
                        base: "",
                        active: {
                          on: "rounded-lg bg-neutral-500 text-white",
                          off: "rounded-lg hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-700 dark:hover:text-white",
                        },
                      },
                    },
                  },
                },
              }}
              // eslint-disable-next-line
              style="pills">
              <Tabs.Item active title="English 🇬🇧">
                <h1 className="text-3xl md:text-5xl font-extrabold dark:text-white mt-10">
                  Privacy Policy
                </h1>
                <p className="mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300 mt-4">
                  This Privacy Policy explains how HackTricks Training collects,
                  uses, and protects your personal information when you visit
                  our website and subscribe and access our online cybersecurity
                  courses.
                </p>
                <ol className="nested space-y-5 list-inside mb-10">
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Information we collect
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      We collect information that you provide to us when you
                      sign up for our courses or contact us through our website.
                      This may include your name, email address, billing and
                      payment information, and other personal information that
                      you voluntarily provide. We may also collect certain
                      technical information about your use of our website, such
                      as your IP address, browser type, and operating system.
                      This information is used to analyze how our website is
                      being used and to improve our services.
                    </p>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Cookies
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      Our website uses cookies and similar technologies to
                      enhance your experience, analyze website performance, and
                      provide personalized content. Cookies are small data files
                      that are stored on your device when you visit our website.
                      We use cookies for purposes such as:
                      <ul>
                        <li>Remembering your preferences and login details.</li>
                        <li>
                          Understanding how visitors interact with our website.
                        </li>
                        <li>Delivering relevant content and advertisements.</li>
                      </ul>
                      Please note that cookies are essential for the proper
                      functioning of our website. By using our website, you
                      agree to the use of cookies as described in this Privacy
                      Policy.
                    </p>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Use of your information
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      We use your personal information to provide you with
                      access to our online cybersecurity courses and to
                      communicate with you about your account and our services.
                      We may also use your information to send you promotional
                      materials or other information that may be of interest to
                      you. We do not share your personal information with third
                      parties except as required by law or as necessary to
                      provide you with our services. We may use third-party
                      service providers to help us operate our website and
                      process payments, but we only share the information
                      necessary to perform these services.
                    </p>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Security of your information
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      We take reasonable measures to protect your personal
                      information from unauthorized access, use, or disclosure.
                      We use industry-standard security protocols to encrypt
                      sensitive information and store it on secure servers.
                    </p>
                  </li>

                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Your rights
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      You have the right to access and correct the personal
                      information that we hold about you. You may also request
                      that we delete your personal information or restrict how
                      it is used. To exercise these rights, please contact us
                      using the information provided on our website.
                    </p>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Changes to this policy
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      We may update this Privacy Policy from time to time to
                      reflect changes in our practices or legal requirements. We
                      will notify you of any significant changes by posting a
                      notice on our website or by email.
                    </p>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Contact us
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      If you have any questions or concerns about our Privacy
                      Policy, please contact us at
                      training-support@hacktricks.xyz.
                    </p>
                  </li>
                </ol>
                <p className="mb-10 text-lg font-normal text-gray-500 dark:text-neutral-300">
                  Last updated: December 14th, 2024.
                </p>
              </Tabs.Item>
              <Tabs.Item active title="Español 🇪🇸">
                <h1 className="text-3xl md:text-5xl font-extrabold dark:text-white mt-10">
                  Política de privacidad
                </h1>
                <p className="mb-4 text-lg font-normal text-gray-500 dark:text-neutral-300 mt-4">
                  Esta política de privacidad explica cómo HackTricks Training
                  recopila, utiliza y protege su información personal cuando
                  visita nuestro sitio web y se suscribe y accede a nuestros
                  cursos de ciberseguridad en línea.
                </p>
                <ol className="nested space-y-5 list-inside mb-10">
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Información que recopilamos
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      Recopilamos la información que usted nos proporciona
                      cuando se inscribe en nuestros cursos o se pone en
                      contacto con nosotros a través de nuestro sitio web. Esto
                      puede incluir su nombre, dirección de correo electrónico,
                      información de facturación y pago, y otra información
                      personal que usted proporcione voluntariamente. También
                      podemos recopilar cierta información técnica sobre su uso
                      de nuestro sitio web, como su dirección IP, tipo de
                      navegador y sistema operativo. Esta información se utiliza
                      para analizar cómo se utiliza nuestro sitio web y para
                      mejorar nuestros servicios.
                    </p>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Cookies
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      Nuestro sitio web utiliza cookies y tecnologías similares
                      para mejorar su experiencia, analizar el rendimiento del
                      sitio web y ofrecer contenidos personalizados. Las cookies
                      son pequeños archivos de datos que se almacenan en su
                      dispositivo cuando visita nuestro sitio web. Utilizamos
                      cookies para fines tales como:
                      <ul>
                        <li>Recordar sus preferencias y datos de acceso.</li>
                        <li>
                          Comprender cómo interactúan los visitantes con nuestro
                          sitio web.
                        </li>
                        <li>Ofrecer contenidos y anuncios relevantes.</li>
                      </ul>
                      Tenga en cuenta que las cookies son esenciales para el
                      correcto funcionamiento de nuestro sitio web. Al utilizar
                      nuestro sitio web, usted acepta el uso de cookies tal como
                      se describe en esta Política de privacidad.
                    </p>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Uso de sus datos
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      Utilizamos su información personal para proporcionarle
                      acceso a nuestros cursos de ciberseguridad en línea y para
                      comunicarnos con usted en relación con su cuenta y
                      nuestros servicios. También podemos utilizar sus datos
                      para enviarle material promocional u otra información que
                      pueda ser de su interés. No compartimos su información
                      personal con terceros, salvo en la medida en que lo exija
                      la ley o sea necesario para prestarle nuestros servicios.
                      Podemos utilizar proveedores de servicios externos para
                      que nos ayuden a gestionar nuestro sitio web y procesar
                      los pagos, pero sólo compartimos la información necesaria
                      para prestar estos servicios.
                    </p>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Seguridad de su información
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      Tomamos medidas razonables para proteger su información
                      personal frente al acceso, uso o divulgación no
                      autorizados. Utilizamos protocolos de seguridad estándar
                      del sector para cifrar la información confidencial y
                      almacenarla en servidores seguros.
                    </p>
                  </li>

                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Sus derechos
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      Tiene derecho a acceder a la información personal que
                      tenemos sobre usted y a rectificarla. También puede
                      solicitar que eliminemos su información personal o que
                      restrinjamos su uso. Para ejercer estos derechos, póngase
                      en contacto con nosotros utilizando la información
                      facilitada en nuestro sitio web.{" "}
                    </p>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Cambios en esta política
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      Podemos actualizar esta Política de Privacidad de vez en
                      cuando para reflejar cambios en nuestras prácticas o
                      requisitos legales. Le notificaremos cualquier cambio
                      significativo publicando un aviso en nuestro sitio web o
                      por correo electrónico.
                    </p>
                  </li>
                  <li className="nested mb-4 text-2xl md:text-4xl font-bold dark:text-white">
                    Póngase en contacto con nosotros
                    <p className="my-4 text-lg font-normal text-gray-500 dark:text-neutral-300">
                      Si tiene alguna pregunta o duda sobre nuestra política de
                      privacidad, póngase en contacto con nosotros en
                      training-support@hacktricks.xyz.
                    </p>
                  </li>
                </ol>
                <p className="mb-10 text-lg font-normal text-gray-500 dark:text-neutral-300">
                  Última actualización: 14 de diciembre de 2024.
                </p>
              </Tabs.Item>
            </Tabs>
          </section>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Privacy
